<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">建模接种</h3>
    <div class="detail">
      <h4 class="title">建模接种</h4>
      <el-form ref="vaccinaReview" size="small" :model="modelVacc" class="addForm" label-suffix=":"
               label-width="100px" :disabled="disabled">
        <el-tabs v-model="nowNumber" class="tabsCage" type="card" @tab-click="clickTab">
          <el-tab-pane
              :key="key"
              v-for="(item,key) in cageMap"
              :label="'LS '+key"
              :name="key"
          >
            <div class="desc">
              <span>笼架编号：{{ item[0].frameNo }}</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-table
            :data="vaccDetailListTab" border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            style="width: calc(100% - 12px);font-size:14px;color:#333">
          <el-table-column
              prop="overbit" width="100"
              label="耳号"
              show-overflow-tooltip></el-table-column>
          <el-table-column
              label="性别" width="60"
              show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column class-name="isClass"
                           label="接种方式">
            <template slot-scope="scope">
              <!--             @change="changeSits($event,scope.$index)"-->
              <el-checkbox-group v-model="sits[scope.$index].list" class="checkBox">
                <el-checkbox :label="item.id" :value="item.id" v-for="item in susList" :key="item.id">
                  <span>试剂种类：{{ item.cellType }}</span>
                  <span class="ml-2">接种方式：{{ item.vaccSite }}</span>
                </el-checkbox>
              </el-checkbox-group>
            </template>
          </el-table-column>
          <el-table-column class-name="isClass"
                           label="是否接种成功">
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.isFail" class="radioGroup">
                <el-radio :label="0">是</el-radio>
                <el-radio :label="1">否</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column class-name="isClass"
                           label="备注">
            <template slot-scope="scope">
              <el-input class="remark"
                        size="small"
                        placeholder="请输入备注信息"
                        v-model="scope.row.remark"></el-input>
            </template>
          </el-table-column>
        </el-table>
        <h4 class="title" style="margin-top:16px">指定复核人</h4>
        <el-form-item label="复核人员">
          <el-radio-group v-model="modelVacc.reviewName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                      @click.native="getAppId(item.userId,item.name)"
            > {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="fromSave">
        <template v-if="state==0||state==3">
          <el-button type="primary" size="small" @click="saveRecord(1)">配制完成</el-button>
          <el-button plain type="primary" size="small" @click="saveRecord(0)">保存</el-button>
        </template>
        <el-button plain type="primary" size="small" @click="$emit('changePageGroup',false,2)">导出预览</el-button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "vaccinationEnd",
  props: ['id', 'status'],
  data() {
    return {
      modelVacc: {
        reviewer: null,
        reviewName: "",
        vaccDetailList: []
      },
      vaccDetailListTab: [],
      userList: [],
      state: null,
      cageMap: {},
      nowNumber: '',
      prevNumber: '',
      // 接种部位
      susList: [],
      //存放
      saveList: [],
      // 是否第一次加载
      isFirst: true,
      firstIndex: null,
      sits: [],
      setSits: [],
      disabled: false
    }
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
    this.getData()
    this.getSusList()


  },
  methods: {
    getAppId(id, name) {
      this.modelVacc.reviewer = id
      this.modelVacc.reviewName = name
    },
    // 获取接种部位
    getSusList() {
      this.$get('/model/susDetail/list/' + this.id).then(res => {
        if (res.status == 200) {
          this.susList = res.data
        }
      })
    },
    //sits
    changeSits(ev, index) {
      // let obj = {list: []}
      // obj.list = ev
      // this.setSits[this.nowNumber][index] = obj;
    },
    getData() {
      this.$get('/model/vacc/' + this.id).then(res => {
        if (res.status == 200) {
          this.cageMap = res.data.cageMap
          let arr = []
          if (this.nowNumber == 0) {
            for (const key in res.data.cageMap) {
              arr.push(key)
              this.saveList[key] = res.data.cageMap[key]
            }
            this.nowNumber = arr[0]
            this.prevNumber = arr[0]
          }

          this.getDataWay()
          this.isFirst = false

          this.state = res.data.modelVacc.state
          if (this.state == 1 || this.state == 2) {
            this.disabled = true
          }
          this.modelVacc.reviewName = res.data.modelVacc.reviewName
          this.modelVacc.reviewer = res.data.modelVacc.reviewer

          // this.vaccDetailListTab = res.data.cageMap[this.nowNumber]
        }
      })
    },
    clickTab(ev) {
      this.saveList[this.prevNumber] = this.vaccDetailListTab
      this.prevNumber = ev.name;
      this.firstIndex = ev.index
      this.getDataWay()
    },
    getDataWay() {
      let vm = this;
      if (this.cageMap[this.nowNumber].length > 0) {
        if (this.setSits[this.nowNumber]) {
          vm['sits'] = this.setSits[this.nowNumber];
        } else {
          vm.sits = []
          this.cageMap[this.nowNumber].forEach(item => {
            let obj = {list: []};
            for (let i = 0; i < item.modelSusDetailList.length; i++) {
              obj.list.push(item.modelSusDetailList[i].id)
            }
            vm.sits.push(obj)
          })
          vm.setSits[this.nowNumber] = vm.sits
        }

        if (this.isFirst) {
          this.vaccDetailListTab = this.cageMap[this.nowNumber]
        } else {
          this.vaccDetailListTab = this.saveList[this.prevNumber]
        }
      }
    },

    saveRecord(state) {
      let endResult = [];
      let isAre;
      let message = [];
      for (const key in this.saveList) {
        this.saveList[key].forEach((item, index) => {
          if (this.setSits[key]) {
            item.susDetailIds = this.setSits[key][index].list.toString()
          } else {
            let arr = []
            for (let i = 0; i < item.modelSusDetailList.length; i++) {
              arr.push(item.modelSusDetailList[i].id)
            }
            item.susDetailIds = arr.toString()
          }
          if (item.susDetailIds == null) {
            isAre = !isAre && false
            message.push("接种部位不能为空")
          } else {
            endResult.push(item)
          }
          item.modelSusDetailList = null
        })

      }
      if (message && message.length > 0) {
        return this.$message({message: '所有笼舍接种部位不能为空', type: 'warning'})
      }
      this.modelVacc.vaccDetailList = endResult
      this.modelVacc.state = state
      this.modelVacc.subId = Number(this.id)

      this.$putJson('/model/vacc', this.modelVacc).then(res => {
        if (res.status == 200) {
          this.$message.success("提交成功")
          this.$router.go(-1)
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
